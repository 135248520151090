import React, { useState } from "react";
import "./advertisement.css";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

function Mymodal({ show, onHide, product }) {
  if (!product) {
    return null;
  }
  const { des,title } = product;
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h6 className="fw-bold">{title}</h6>
        <p>{des}</p>
        <div className="my-3">
          <Link to="/contact-us" className="btn btn-main-outlite">
            More Info
          </Link>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const Advertisement = () => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setModalShow(true);
  };
  const image = [
    {
      Image: "../../Images/Ad/add2.jpg",
      title: "Theatre On-Screen Ads",
      des: "Experience the magic of the stage brought to life on the big screen with our captivating theatre on-screen advertisements.",
    },
    {
      Image: "../../Images/Ad/offscreen.jpeg",
      title: "Theatre Off-Screen Ads",
      des: "Theatre Off-Screen advertisements captivate audiences with vibrant visuals and engaging storytelling that leave a lasting impact.",
    },
    {
      Image: "../../Images/Ad/bording1.jpg",
      title: "Hoarding",
      des: "Hoarding Ads create lasting impressions due to their eye-catching designs and compelling visuals.",
    },
    {
      Image: "../../Images/Ad/bus4.webp",
      title: "Bus rear panel Ads",
      des: "Capture attention everywhere you go with our vibrant and eye-catching bus rear panel advertisements.",
    },
    {
      Image: "../../Images/Ad/auto2.webp",
      title: "Auto rear panel Ads",
      des: "The auto rear panel ads offers a sleek and modern design, enhancing the vehicle's overall appearance effortlessly.",
    },
    {
      Image: "../../Images/Ad/Busshelter.png",
      title: "Bus Shelter Ads",
      des: "Utilizing bus shelter advertising allows businesses to increase brand awareness and generate significant exposure in high-traffic areas.",
    },
    {
      Image: "../../Images/Ad/arch.jpg",
      title: "Arch Advertising",
      des: "Elevate your interior décor effortlessly with our exquisite range of arches, designed to inspire and captivate.",
    },
    {
      Image: "../../Images/Ad/televison3.jpg",
      title: "Television Ads",
      des: "These Ads showcase innovative products and services that enhance everyday life, sparking curiosity and interest.",
    },
    {
      Image: "../../Images/Ad/traffic2.jpg",
      title: "Traffic Signal Ads",
      des: "Reach thousands of potential customers daily by showcasing your product on our strategically placed traffic signal displays",
    },
    {
      Image: "../../Images/Ad/pole1.jpg",
      title: "Pole Kiosk Ads",
      des: "Utilizing pole kiosk advertising enables businesses to reach a diverse audience, boosting brand awareness and attracting potential customers.",
    },
    {
      Image: "../../Images/Ad/mobilevan3.jpg",
      title: "Mobile Van Ads",
      des: "The versatility of mobile van advertising allows for strategic targeting of specific demographics, ensuring a tailored approach to reach the right audience.",
    },
    {
      Image: "../../Images/Ad/shoot1.png",
      title: "Film making & Photo shoot",
      des: "Transform your creative ideas into stunning visuals through our innovative film-making and photo shoot expertise, delivering top-notch quality and professionalism.",
    },
    {
      Image: "../../Images/Ad/news1.jpg",
      title: "News paper Ads",
      des: "Newspaper advertisements effectively reach a wide audience, ensuring maximum exposure for your product or service.",
    },
    {
      Image: "../../Images/Ad/radio3.jpg",
      title: "Radio Ads",
      des: "Radio Ads provide a platform for businesses to showcase their unique selling propositions and stand out in the market.",
    },
    {
      Image: "../../Images/Ad/pole3.jpeg",
      title: "Unipole Hoarding",
      des: "Unipole Hoarding Ads captivate attention with their larger-than-life visuals, ensuring your message stands out amidst the cityscape.",
    },
  ];

  return (
    <>
      <div className="breadcrumb-area py-5">
        <div className="">
          <Link to="/" className="non-active-content">
            Home
          </Link>
          <span className="mx-2">/</span>
          <Link to="/advertisement" className="active-content">
            Advertisement
          </Link>
        </div>
      </div>
      {/* <div className="container advertisement">
        {image.map((i) => (
          <>
            <div className="ad-image hr">
              <Link to="/shop">
                <img className="image-1" src={i.Image1} />
              </Link>
            </div>
            <div className="d-flex flex-wrap">
              <div className="col-sm-4 col-12 px-sm-2 px-0">
                <div className="ad-image image-2">
                  <Link to="/shop">
                    <img className="image-2" src={i.Image5} />
                  </Link>
                </div>
              </div>
              <div className="col-sm-4 col-12 px-sm-2 px-0">
                <div className="ad-image image-2">
                  <Link to="/shop">
                    <img className="image-2" src={i.Image6} />
                  </Link>
                </div>
              </div>
              <div className="col-sm-4 col-12 px-sm-2 px-0">
                <div className="ad-image image-2">
                  <Link to="/shop">
                    <img className="image-2" src={i.Image7} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="ad-image hr">
              <Link to="/shop">
                <img className="image-1" src={i.Image4} />
              </Link>
            </div>
            <div className="d-flex flex-wrap">
              <div className="col-sm-6 col-12 px-sm-2 px-0">
                <div className="ad-image">
                  <Link to="/shop">
                    <img className="image-2" src={i.Image2} />
                  </Link>
                </div>
              </div>
              <div className="col-sm-6 col-12 px-sm-2 px-0">
                <div className="ad-image">
                  <Link to="/shop">
                    <img className="image-2" src={i.Image3} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="ad-image hr">
              <Link to="/shop">
                <img className="image-1" src={i.Image8} />
              </Link>
            </div>
          </>
        ))}
      </div> */}
      <div className="container advertisement">
        <div className="d-flex flex-wrap">
          {image.map((i) => (
            <>
              <div
                className="col-lg-4 col-sm-6 col-12 px-sm-2 px-0"
                onClick={() => handleProductClick(i)}
              >
                <div className="ad-image image-2">
                  <img className="image-2" src={i.Image} />
                </div>
                <p className="text-center ">{i.title}</p>
              </div>
              <Mymodal
                show={modalShow}
                onHide={() => setModalShow(false)}
                product={selectedProduct}
              />
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default Advertisement;
