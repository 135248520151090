import React from "react";
import "./successstory.css";

const SuccessStory = () => {
  const data = [
    {
      title: "Successful Events",
      count: "2000+",
      icon: "fa-solid fa-calendar-day",
    },
    {
      title: "Years Experience",
      count: "15+",
      icon: "fa-solid fa-hand-holding-heart",
    },
    {
      title: "Happy Clients",
      count: "300+",
      icon: "fa-regular fa-face-smile",
    },
    {
      title: "Team Members",
      count: "50+",
      icon: "fa-solid fa-people-group",
    },
  ];

  return (
    <>
      <div className="success-story py-md-5 py-3 my-md-4 my-2 ">
        <div className="container">
          <h4 className="text-center mb-5">
          Strategic Events, Lasting Impressions
          </h4>
          <div className="d-flex flex-wrap justify-content-center align-items-center my-3 success-story-data">
            {data.map((i) => (
              <div className="col-md-3 col-sm-6 col-12 my-3 d-flex flex-column flex-wrap align-items-center">
                <i className={i.icon}></i>
                <h6 className="my-4">{i.count}</h6>
                <p>{i.title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessStory;
