import { Link } from "react-router-dom";
import Hero from "./Component/Hero/hero";

function Aboutus() {
  return (
    <>
      <div className="breadcrumb-area py-5">
        <div className="">
          <Link to="/" className="non-active-content">
            Home
          </Link>
          <span className="mx-2">/</span>
          <Link to="/about-us" className="active-content">
            About Us
          </Link>
        </div>
      </div>
      <Hero/>
    </>
  );
}

export default Aboutus;
