import React from "react";
import { Link } from "react-router-dom";
import "./termsandconditions.css";

const TermsAndConditions = () => {
  return (
    <>
      <div className="breadcrumb-area py-5">
        <div className="">
          <Link to="/" className="non-active-content">
            Home
          </Link>
          <span className="mx-2">/</span>
          <Link to="/terms-and-conditions" className="active-content">
            Terms And Conditinos
          </Link>
        </div>
      </div>
      <div className="container terms-and-conditions py-3">
        <p className="py-3">
          Welcome to Jaya Signatures Private Limited. These terms and conditions
          outline the rules and regulations for the use of our website.
        </p>

        <h2>1. Intellectual Property Rights</h2>
        <p>
          Unless otherwise stated, Jaya Signatures Private Limited and its
          licensors own the intellectual property rights for all material on our
          website. All intellectual property rights are reserved.
        </p>

        <h2>2. Restrictions</h2>
        <p>You are specifically restricted from all of the following:</p>
        <ul>
          <li>Publishing any website material in any other media.</li>
          <li>
            Selling, sublicensing, and/or otherwise commercializing any
            material.
          </li>
          <li>
            Using this website in a way that is damaging, illegal, or harmful.
          </li>
        </ul>

        <h2>3. Limitation of Liability</h2>
        <p>
          In no event shall Jaya Signatures Private Limited, nor any of its
          officers, directors, and employees, be held liable for anything
          arising out of or in any way connected with your use of this website.
        </p>

        <h2>4. Governing Law & Jurisdiction</h2>
        <p>
          These terms will be governed by and interpreted in accordance with the
          laws of India, and you submit to the non-exclusive
          jurisdiction of the state and federal courts located in Chennai
          for the resolution of any disputes.
        </p>

        <h2>5. Privacy Policy</h2>
        <p>
          Our Privacy Policy outlines how we collect, use, disclose, and manage
          your personal information. Please refer to our Privacy Policy for more
          information.
        </p>

        <h2>6. Changes to Terms</h2>
        <p>
          Jaya Signatures Private Limited reserves the right to revise these
          terms at any time as it sees fit. By using this website, you agree to
          review these terms regularly.
        </p>

        <h2>7. Contact Information</h2>
        <p>
          Jaya Signatures Private Limited reserves the right to revise these
          terms at any time as it sees fit. By using this website, you agree to
          review these terms regularly.
        </p>
      </div>
    </>
  );
};

export default TermsAndConditions;
