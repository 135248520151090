import "../App.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Hero from "../Hero/hero";
import Support from "../Support/support";
import DailyUpdates from "../Dailyupdates/dailyupdates";
import Collections from "../Collections/collections";
import Testimonial from "../Testimonials/testimonial";
import Clients from "../Clients/clients";
import Maintitle from "../MainTitle/maintitle";

function Home() {
  return (
    <>
      <Hero />
      <div className="container">
        <Maintitle
          title="About Jayapradha Mohanlal"
          className="about-title-jaya"
        />
        <div className="col-sm-8 col-12 mx-auto font-italic about-main-content">
          <p className="text-center">
            " Hey, I'm Jayapradha Mohanlal , our boutique epitomizes
            sophistication and style. Embracing curated elegance, we present a
            thoughtfully chosen collection, reflecting our commitment to
            exceptional quality and individuality. Driven by an entrepreneurial
            spirit, our goal is to redefine fashion experiences, empowering
            individuals to confidently embrace their uniqueness. Join us on a
            journey where luxury intertwines with innovation, where each piece
            narrates a tale of craftsmanship and inspiration."
          </p>
        </div>
      </div>
      {/* <Support /> */}
      <Collections />
      <DailyUpdates />
      <Testimonial />
      <Clients />
      <Maintitle
          title="Our Location"
        />
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.1172132773872!2d80.20671907489721!3d13.09175721223293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265c62bd9b6e9%3A0x5a3d0c7e81523f7d!2sJaya%20Signatures%20Boutique!5e0!3m2!1sen!2sin!4v1701931216103!5m2!1sen!2sin"
        style={{ width: "100%" }}
        height="400"
        allowfullscreen=""
        loading="lazy"
        className="my-md-5 my-3"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </>
  );
}

export default Home;
