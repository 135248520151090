import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Alerts from "./Alerts";
export const Emailcontactform = () => {
  const form = useRef();

  const [showAlert, setShowAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [selectedOptionError, setSelectedOptionError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // const handleSubmit = (e) => {
  //   let valid = true;
  //   e.preventDefault();

  //   emailjs
  //     .sendForm(
  //       "service_48ge39t",
  //       "template_s8zmapu",
  //       form.current,
  //       "-JY8G53c7vnSHKeRV"
  //     )

  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //         handleInput();
  //         alert("Form Submitted Successfully");
  //         setShowAlert(true);
  //         setTimeout(() => {
  //           setShowAlert(false);
  //         }, 3000);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //         alert("Form not Submitted Successfully");
  //       }
  //     );
  //   Validation for name
  //   if (name.trim().length === 0) {
  //     setNameError('Name is required');
  //     valid = false;
  //   } else {
  //     setNameError('');
  //   }

  //   // // Validation for email
  //   // if (email.trim().length === 0) {
  //   //   setEmailError('Email is required');
  //   //   valid = false;
  //   // } else {
  //   //   setEmailError('');
  //   // }

  //   // // Validation for phone
  //   // if (phone.trim().length === 0) {
  //   //   setPhoneError('Phone number is required');
  //   //   valid = false;
  //   // } else {
  //   //   setPhoneError('');
  //   // }

  //   // // Validation for location
  //   // if (location.trim().length === 0) {
  //   //   setLocationError('Location is required');
  //   //   valid = false;
  //   // } else {
  //   //   setLocationError('');
  //   // }

  //   // If all inputs are valid, proceed with submission
  //   if (valid) {
  //     // Perform submission logic here
  //     // console.log('Submitting:', { name, email, phone, location, message });
  //     // postPatient();
  //   handleClearInputs();
  //     setShowAlert(true);
  //     setTimeout(() => {
  //       setShowAlert(false);
  //     }, 3000);
  //     // alert("thank you for submitting")
  //   }
  // };
  const handleClearInputs = () => {
    setName("");
    setEmail("");
    setPhone("");
    setLocation("");
    setMessage("");
    setSelectedOption("");
    setShowAlert("");
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    location: "",
    message: "",
  });

  const handleInput = () => {
    setFormData({
      name: "",
      email: "",
      mobile: "",
      location: "",
      message: "",
    });
  };

  const [errors, setErrors] = useState({
    email: false,
    mobile: false,
  });

  const handleInputChange = (event1) => {
    const newName = event1.target.value;
    setName(newName);

    const namePattern = /^[A-Za-z]{1,32}$/;
    if (!namePattern.test(newName)) {
      setNameError(
        "Please enter a valid name (letters only, up to 32 characters)."
      );
    } else {
      setNameError("");
    }
  };
  const handleEmailChange = (event2) => {
    const newEmail = event2.target.value;
    setEmail(newEmail);

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(newEmail)) {
      setEmailError("Please enter a valid email address.");
    } else {
      setEmailError("");
    }
  };
  const handlemobileInputChange = (event3) => {
    const newPhone = event3.target.value;
    setPhone(newPhone);

    // Validate input against a phone number pattern
    const phonePattern = /^\d{10}$/;
    // const phonePattern = /^\d{3}-\d{2}-\d{3}$/; // Pattern for XXX-XX-XXX format
    if (!phonePattern.test(newPhone)) {
      setPhoneError("Please enter a valid phone number");
    } else {
      setPhoneError("");
    }
  };
  const handlelocationInputChange = (event4) => {
    const newLocation = event4.target.value;
    setLocation(newLocation);

    // Validate against a minimum length (e.g., at least 3 characters)
    if (newLocation.trim().length < 3) {
      setLocationError("Location must be at least 3 characters.");
    } else {
      setLocationError("");
    }
  };
  const handleTextChange = (event6) => {
    const newText = event6.target.value;
    setMessage(newText);

    // Validate the text field (example: ensuring it's not empty)
    if (newText.trim() === "") {
      setMessageError("Message is required");
    } else {
      setMessageError("");
    }
  };
  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   // Dynamically handle input validation based on the input field name
  //   if (name === 'mobile') {
  //     const pattern = /^\d{10}$/;
  //     setErrors({ ...errors, [name]: !pattern.test(value) });
  //   } else if (name === 'email') {
  //     const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     setErrors({ ...errors, [name]: !emailPattern.test(value) });
  //   }else if(name==='name'){
  //     const namePattern=  /^[A-Za-z]{1,32}$/;
  //     setErrors({...errors,[name]: !namePattern.test(value)});
  //   }

  //   setFormData({
  //     ...formData,
  //     [name]: value
  //   });
  // };

  const value = [
    {
      name: name,
      email: email,
      phone: phone,
      location: location,
      message: message,
    },
  ];

  const sendEmail = (e) => {
    e.preventDefault();

    // handleInput();
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phonePattern = /^\d{10}$/; // Pattern for XXX-XX-XXX format
    if (!phonePattern.test(phone) && !emailPattern.test(email))  {
      
      console.log("not working")
      alert("Form Not Submitted Sucessfully");
    } else {
      alert("Form Submitted Successfully");
      
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 3000);
  
      emailjs.sendForm(
        "service_48ge39t",
        "template_s8zmapu",
        form.current,
        "-JY8G53c7vnSHKeRV"
      );
      
    }
  };

  return (
    <>
      <form ref={form} onSubmit={sendEmail}>
        <h4 className="my-4">Get In Touch</h4>
        <div className="d-flex flex-wrap justify-content-center">
          <div className="col-6 px-2">
            <input
              type="text"
              name="name"
              // value={formData.name}
              value={name}
              className="form-control"
              placeholder="Enter your Name"
              onChange={handleInputChange}
              // placeholder="Enter name"
              // style={{ border: errors.name ? ' solid red' : ' ' }}
              required
              error={!!nameError}
              helperText={nameError}
            />
          </div>
          <div className="email col-6 px-2">
            <input
              type="email"
              className="form-control"
              placeholder="Enter your email"
              name="email"
              value={email}
              // value={formData.email}
              onChange={handleEmailChange}
              // placeholder="Enter email"
              // style={{ border: errors.email ? ' solid red' : ' ' }}
              required
              error={!!emailError}
              helperText={emailError}
            />
            {emailError && <p style={{ color: "red" }}>{emailError}</p>}
          </div>
          <div className="mobile col-6 px-2">
            <input
              type="text"
              className="form-control"
              placeholder="Enter your mobile number"
              name="mobile"
              // value={formData.phone}
              value={phone}
              // value={formData.mobile}
              onChange={handlemobileInputChange}
              // onChange={handleChange}
              // placeholder="Enter email"
              // style={{ border: errors.mobile
              //    ? ' solid red' : ' ' }}
              required
              error={!!phoneError}
              helperText={phoneError}
            />
            {phoneError && <p style={{ color: "red" }}>{phoneError}</p>}
          </div>
          <div className="location col-6 px-2">
            <input
              type="text"
              className="form-control"
              name="location"
              placeholder="Enter Location"
              onChange={handlelocationInputChange}
              value={location}
              // value={formData.location}
              // placeholder="Enter name"
              required
              error={!!locationError}
              helperText={locationError}
            />
          </div>
          <div className="col-12 my-3 px-2">
            <textarea
              type="text"
              name="message"
              value={message}
              // value={formData.message}
              className="form-control"
              placeholder="Enter your Message"
              onChange={handleTextChange}
              rows="3"
            ></textarea>
          </div>
          <div className="btn ">
            <input className="btn-main-outlite" type="submit" value="Send" />
          </div>
          {showAlert && <Alerts />}
        </div>
      </form>
    </>
  );
};
