import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../src/Home/home';
import Aboutus from '../src/Aboutus/aboutus';
import Layout from './Layout/layout';
import NotFound from './NotFound/notfound';
import Contactus from './Contactus/contactus';
import Shop from './Shop/shop';
import Shopsingle from './Shopsingle/shopsingle';
import TermsAndConditions from './TermsAndConditions/termsandconditions';
import PrivacyPolicy from './PrivacyPolicy/privacypolicy';
import ScrollToTop from './ScrollToTop/scrolltotop';
import Main from './Main/main';
import Advertisement from './Advertisement/advertisement';
import Events from './Events/events';
function App() {
  return (
      <Router >
        <ScrollToTop />
        <Layout>
        <Routes>
          <Route  exact path="/" element={<Main/>}/>
          <Route path="/home" element={<Home />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/contact-us" element={<Contactus/>}/>  
          <Route path="/shop" element={<Shop/>}/>
          <Route path="/shop/:data" element={<Shopsingle/>}/>
          <Route path="*" element={<NotFound />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/advertisement" element={<Advertisement/>}/>
          <Route path="/events" element={<Events/>}/>
        </Routes>
        </Layout>
      </Router>
  );
}

export default App;
