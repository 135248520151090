import React from "react";
import "./herovideo.css";

const HeroVideo = () => {
  return (
    <>
      <div className="position-relative hero-main">
        <video autoPlay muted loop id="myVideo">
          <source src="../../Images/Video/hero1.mp4" type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>
        <div className="hero-main-content col-md-6 col-12 px-sm-0 px-3">
          <h1 className="py-md-5 py-3 text-center">
            Jaya Signatures Private Limited
          </h1>
          {/* <p>
          Welcome to the gateway of unforgettable experiences! Elevate your events with our expert touch and turn moments into memories. Let our innovative advertising solutions amplify your brand's story.
          </p> */}
          <p>
            Experience the magic of Exquisite Events, where every occasion is
            meticulously crafted into a cherished memory. Through captivating advertisements, we weave tales
            of luxury, inviting you to indulge in a world where style meets
            substance, leaving an indelible mark on your senses.
          </p>
        </div>
      </div>
    </>
  );
};

export default HeroVideo;
