import { Link } from "react-router-dom";
import "./collections.css";
import Maintitle from "../MainTitle/maintitle";
function Collections() {
  const collections = [
    {
      Image:
        "https://5.imimg.com/data5/SELLER/Default/2022/12/YU/RQ/IA/9664580/1-500x500.jpg",
      Title: "Kurti",
      Path: "Kurti",
    },
    {
      Image:
        "https://thepuremeraki.com/wp-content/uploads/2021/02/best-boutique-chennai-9.jpg",
      Title: "Full Set",
      Path: "fullset",
    },
    {
      Image:
        "https://cdn.storehippo.com/s/62ea2c599d1398fa16dbae0a/64cb78170660c53cbd5ebd4f/webp/41uuh46iz0s-_sx679-_sx-_ux-_sy-_uy_.jpg",
      Title: "Bottom",
      Path: "bottom",
    },
    {
      Image:
        "https://maharanidesigner.com/wp-content/uploads/2020/09/Boutique-Embroidery-Suits.jpeg",
      Title: "Party Wear",
      Path: "partywear",
    },
    {
      Image:
        "https://ethnicrace.com/wp-content/uploads/2023/08/Purple-Indo-Western-Chinon-Crop-Top-Lehenga-With-Dupatta-3-1.webp",
      Title: "Indo western",
      Path: "indowestern",
    },
    {
      Image:
        "https://www.karagiri.com/cdn/shop/products/banarasi-saree-golden-green-zari-woven-banarasi-saree-silk-saree-online-15839851839591.jpg?v=1648528348",
      Title: "Saree",
      Path: "saree",
    },
  ];
  return (
    <>
      {/* <div className="collections">
        <div className="d-flex justify-content-center align-items-center">
          <h5 className="hero-title my-md-5 my-3">Our Collections</h5>
        </div>
      </div> */}
       <Maintitle title="Our Collections" />
      <div className="container categories-view">
        {collections.map((i, index) => (
          <Link
            to={`/shop/${i.Path}`}
            key={index}
            className="d-flex flex-column align-items-center justify-conent-center view"
          >
            <div className="position-relative rotate">
              <img className="product-logo" src={i.Image} alt={i.Title} />
            </div>
            <p className="mb-0 my-3 fw-bold">{i.Title}</p>
          </Link>
        ))}
      </div>
    </>
  );
}

export default Collections;
