import React from "react";
import { Link } from "react-router-dom";
import "./events.css";
const Events = () => {
  const data = [
    {
      // Month: "may",
      // date: "23",
      Image:"../../Images/Events/corporate.jpg",
      Title: "CORPORATE EVENTS",
      // Time: "9.00am - 12.00pm",
      Des1: "Jaya Signatures specializes in planning, organizing, and executing events for businesses, organizations, and institutions. These events encompass a wide range, including conferences, seminars, product launches, and celebrations.",
      Des2: "Corporate event management involves a blend of strategic planning, creative execution, and flawless coordination to deliver memorable and impactful experiences tailored to the needs and objectives of the corporate world.",
    },
    {
      // Month: "july",
      // date: "28",
      Image:"../../Images/Events/wedding1.avif",
      Title: "WEDDING EVENTS",
      // Time: "6.00pm - 9.00pm",
      Des1: "A wedding event is a ceremonial celebration that marks the union of two individuals in marriage. It involves rituals, traditions, and ceremonies that vary across cultures, with the primary aim of celebrating the love and commitment between the couple.",
      Des2: "Wedding events are significant milestones that celebrate love, commitment, and new beginnings. They encompass various traditions, planning stages, and emotional aspects, making each celebration unique and memorable.",
    },
    {
        // Month: "August",
        // date: "18",
        Image:
          "../../Images/Events/branding.webp",
        Title: "BRAND ACTIVATION ACTIVITY",
        // Time: "10.00am - 1.00pm",
        Des1: "Brand activation refers to marketing initiatives that aim to bring a brand's essence to life, creating engaging and interactive experiences to connect directly with the target audience.",
        Des2: "Brand activation activities serve as a strategic approach to directly engage consumers, create memorable experiences, and drive favorable actions that positively impact brand perception and consumer behavior.",
      },
      {
        // Month: "September",
        // date: "10",
        Image:
          "../../Images/Events/Catering.jpeg",
        Title: "CATERING EVENTS",
        // Time: "6.00pm - 9.00pm",
        Des1: "In Jaya Signatures, we specialize in providing food and beverage services for gatherings, celebrations, or occasions, whether it's an intimate private gathering or a large-scale corporate event.",
        Des2: "Catered events play a pivotal role in elevating the overall experience of diverse gatherings and occasions, offering more than just food; they significantly contribute to the success of the event and ensure guest satisfaction.",
      },
  ];
  return (
    <>
      <div className="breadcrumb-area py-5">
        <div className="">
          <Link to="/" className="non-active-content">
            Home
          </Link>
          <span className="mx-2">/</span>
          <Link to="/events" className="active-content">
            Events
          </Link>
        </div>
      </div>
      <div className="container">
        {data.map((i) => (
          <div className="d-flex flex-wrap  justify-content-center events py-3 my-2">
            <div className="col-md-6 col-12 d-flex flex-wrap">
              <div className="col-md-2 col-12">
                <h6 className="event-month text-center">{i.Month}</h6>
                <p className="event-day text-center">{i.date}</p>
              </div>
              <div className="col-md-10 col-12 px-md-3 px-0">
                <img
                  className="event-image"
                  src={i.Image}
                />
              </div>
            </div>
            <div className="col-md-6 col-12 my-md-0 my-3">
              <p className="event-title mb-0">{i.Title}</p>
              {/* <p className="event-time my-3">Timing: {i.Time}</p> */}
              <p className="event-des">
              {i.Des1}
              </p>
              <p className="event-des">
              {i.Des2}
              </p>
              <Link to="/contact-us" className="btn btn-main-outlite">More Info</Link>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Events;
