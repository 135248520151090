import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./hero.css";
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <>
      <div className="swiper-hero">
        <div className="container px-0">
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="d-flex flex-wrap justify-content-between align-items-center h-100">
                <div className="col-md-6 col-12 p-5 hero-content">
                  <p>Featured Collection</p>
                  <h1>
                  Explore Our Signature <br /> Collection
                  </h1>
                  <Link className="btn btn-main-outlite" to="/shop">SHOP NOW</Link>
                </div>
                <div className="col-md-6 col-12 d-flex justify-content-center">
                  <img src="../../Images/Main/home-main-1.png" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="d-flex  flex-wrap justify-content-between align-items-center h-100">
                <div className="col-md-6 col-12 p-5 hero-content">
                  <p>Special Offers</p>
                  <h1>
                    Exclusive offer <br /> Visit Us <br/> Today
                  </h1>
                  <Link className="btn btn-main-outlite" to="/shop">SHOP NOW</Link>
                </div>
                <div className="col-md-6 col-12 d-flex justify-content-center">
                  <img src="../../Images/Main/home-main-2.png" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
}
