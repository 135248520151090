import React from "react";
import { Link } from "react-router-dom";
import "./privacypolicy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="breadcrumb-area py-5">
        <div className="">
          <Link to="/" className="non-active-content">
            Home
          </Link>
          <span className="mx-2">/</span>
          <Link to="/privacy-policy" className="active-content">
            Privacy Policy
          </Link>
        </div>
      </div>
      <div className="container privacy-policy py-3">
        <p className="py-3">
          At <b>Jaya Signatures Private Limited</b>, we are committed to ensuring the privacy and
          security of your personal information. This Privacy Policy outlines
          how we collect, use, disclose, and manage your personal information
          when you use our website or engage with our services
        </p>

        <h2>1. Information We Collect</h2>
        <p>We may collect personal information including but not limited to:</p>
        <ul>
          <li>Contact details - <a href="tel:9884413123" className="text-primary">9884413123</a></li>
          <li>Billing and shipping address. (No: Y -79, 3rd Floor, 6th Street, Y-Block ,Anna Nagar,
Chennai,Tamil Nadu 600040)</li>
          <li>Payment information.</li>
          <li>Demographic information.</li>
          <li>User preferences and interactions with <Link to="/" className="text-primary">our website</Link>.</li>
        </ul>

        <h2>2. How We Use Your Information</h2>
        <p>We use your personal information to:</p>
        <ul>
          <li>Process and fulfill your orders.</li>
          <li>Communicate with you regarding your purchases and inquiries.</li>
          <li>Provide customer support and respond to your requests.</li>
          <li>
            Personalize your experience and tailor our offerings to your
            preferences.
          </li>
          <li>Improve our website, products, and services</li>
        </ul>

        <h2>3. Information Sharing</h2>
        <p>
          We do not sell, trade, or otherwise transfer your personal information
          to outside parties. However, we may share your information with
          trusted third parties who assist us in operating our website or
          servicing you, subject to confidentiality agreements.
        </p>

        <h2>4. Data Security</h2>
        <p>
          We implement various security measures to protect the confidentiality
          and integrity of your personal information. However, no method of
          transmission over the internet or electronic storage is entirely
          secure. We strive to use commercially acceptable means to protect your
          data but cannot guarantee absolute security.
        </p>

        <h2>5. Cookies and Tracking</h2>
        <p>
          Our website may use cookies or similar tracking technologies to
          enhance your browsing experience. You can control cookies through your
          browser settings.
        </p>

        <h2>6. Your Rights</h2>
        <p>
          You have the right to access, correct, or delete your personal
          information. If you wish to update your information or have any
          concerns about how we handle your data, please contact us.
        </p>

        <h2>7. Changes to Privacy Policy</h2>
        <p>
          We reserve the right to modify this Privacy Policy at any time. Any
          changes will be effective immediately upon posting on this page.
        </p>

        <h2>8. Contact</h2>
        <p>
        If you have any questions about this Privacy Policy or our data practices, please <Link className="text-primary" to="/contact-us">contact us</Link>.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
