import { useState } from "react";
import "./products.css";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

function MyVerticallyCenteredModal({ show, onHide, product }) {
  if (!product) {
    return null;
  }
  const {
    productimage,
    title,
    starcount,
    originalprice,
    offerprice,
    offer,
    type,
    productdis,
    addpoints,
    color,
    size,
  } = product;
  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-wrap justify-content-center align-items-start">
          <div className="col-md-6 col-12 px-lg-5  px-2 my-md-0 my-3">
            <img className="modal-image" src={productimage} alt={title} />
          </div>
          <div className="col-md-6 col-12">
            <p className="title fw-bold">{title}</p>
            <p>{productdis}</p>
            <ul>
              {addpoints.map((i) => (
                <li>{i}</li>
              ))}
            </ul>
            <div className="my-3 d-flex flex-wrap align-items-center">
              <p className="mb-0 fw-bold">Color:</p>
              <div className="d-flex flex-wrap ps-3">
                {color.map((color, index) => (
                  <div
                    key={index}
                    style={{
                      background: color,
                      width: "15px",
                      height: "15px",
                      borderRadius: "50%",
                      marginRight: "5px", 
                      marginBottom: "5px",
                    }}
                  ></div>
                ))}
              </div>
            </div>
            <div className="my-3 d-flex flex-wrap align-items-center">
              <p className="mb-0 fw-bold">Size:</p>
              <div className="d-flex flex-wrap ps-3">
                {size.map((size, index) => (
                  <p
                    key={index}
                    style={{
                      padding:"2px 10px",
                      border:"1px solid #dfb665",
                      borderRadius: "3px",
                    }}
                    className="m-2 mb-0"
                  >
                    {size}
                  </p>
                ))}
              </div>
            </div>
            <Link className="btn btn-main-outlite" to="/contact-us">More Info</Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function Products(props) {
  const [modalShow, setModalShow] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setModalShow(true);
  };
  const { page } = props;
  const products = [
    {
      key: "NewArr",
      items: [
        {
          productimage:
            "../Images/DressCollection/jumpsuit.jpg",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Jump suit",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: 25,
          type: "new",
          productdis: "Elegant and versatile design suitable for various events",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/wrapdress.jpg",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Wrap",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: 15,
          type: "new",
          productdis: "Elegant and versatile design suitable for various events",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/ball-gown2.jpg",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Ball Gown",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: 20,
          type: "new",
          productdis: "Elegant and versatile design suitable for various events",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/mini.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Mini",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: 10,
          type: "new",
          productdis: "Elegant and versatile design suitable for various events",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
      ],
    },
    {
      key: "seller",
      items: [
        {
          productimage:
            "../Images/DressCollection/sundress.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "sun dress",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: 50,
          type: "new",
          productdis: "High-quality fabric for comfort and durability",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/apron.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Apron",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: 30,
          type: "new",
          productdis: "High-quality fabric for comfort and durability",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/suede.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Suede",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: 35,
          type: "new",
          productdis: "High-quality fabric for comfort and durability",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/bodycon1.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Bodycon",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: 15,
          type: "new",
          productdis: "High-quality fabric for comfort and durability",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
      ],
    },
    {
      key: "sale",
      items: [
        {
          productimage:
            "../Images/DressCollection/cocktail.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Cock Tail",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "20",
          type: "New",
          productdis: "Available in a range of sizes to cater to different body types",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/halfsari.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Half saree",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: 15,
          type: "new",
          productdis: "Available in a range of sizes to cater to different body types",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/tshirt.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "T-shirt",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: 50,
          type: "new",
          productdis: "Available in a range of sizes to cater to different body types",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/jacket.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Jacket",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: 25,
          type: "new",
          productdis: "Available in a range of sizes to cater to different body types",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
      ],
    },
    {
      key: "Kurti",
      items: [
        {
          productimage:
            "../Images/DressCollection/Kurthi1.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Rust printed kurta with trouser",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "15",
          type: "New",
          productdis: "Easy to accessorize for both casual and formal looks",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/kurti2.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Printed crepe kurta with trouser",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "35",
          type: "New",
          productdis: "Easy to accessorize for both casual and formal looks",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/kurti3.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Yellow muslin printed kurta with cotton trouser",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "10",
          type: "New",
          productdis: "Easy to accessorize for both casual and formal looks",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/kurti4.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Blue velvet embroided kurta set",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "20",
          type: "New",
          productdis: "Easy to accessorize for both casual and formal looks",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
      ],
    },
    {
      key: "fullset",
      items: [
        {
          productimage:
            "../Images/DressCollection/full1.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Green crepe floral printed co prd set",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "18",
          type: "New",
          productdis: "Breathable material ensuring comfort throughout the day",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/full2.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Black woolne inner, trouser & shrug with full white kashmiri embroidery",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "22",
          type: "New",
          productdis: "Breathable material ensuring comfort throughout the day",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/full3.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Pink fully embroided velvet suit",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "8",
          type: "New",
          productdis: "Breathable material ensuring comfort throughout the day",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/full4.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Blacl woolen inner, trouser & fully embroidered shrug",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "30",
          type: "New",
          productdis: "Breathable material ensuring comfort throughout the day",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
      ],
    },
    {
      key: "bottom",
      items: [
        {
          productimage:
            "../Images/DressCollection/bottom1.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Black woolen dress",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "22",
          type: "New",
          productdis: "Trendy and fashionable style that stands out",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/bottom2.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Wine woolen three piece set",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "20",
          type: "New",
          productdis: "Trendy and fashionable style that stands out",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/bottom3.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Black woolen inner trouser and shrug dress",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "32",
          type: "New",
          productdis: "Trendy and fashionable style that stands out",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/bottom4.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Sea green digitally printed silk co-ord set",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "20",
          type: "New",
          productdis: "Trendy and fashionable style that stands out",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
      ],
    },
    {
      key: "partywear",
      items: [
        {
          productimage:
            "../Images/DressCollection/party1.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Pink embroidered nyra cut suit",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "10",
          type: "New",
          productdis: "Suitable for all seasons with its adaptable style",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/party2.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Bottle green rayon silk embroideredanarklai suit",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "15",
          type: "New",
          productdis: "Suitable for all seasons with its adaptable style",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/party3.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Pink fully embroided velvet suit ",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "25",
          type: "New",
          productdis: "Suitable for all seasons with its adaptable style",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/party4.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Wine printed organza suit with embroidery",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "17",
          type: "New",
          productdis: "Suitable for all seasons with its adaptable style",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
      ],
    },
    {
      key: "indowestern",
      items: [
        {
          productimage:
            "../Images/DressCollection/west1.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Black embroidered velvet",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "20",
          type: "New",
          productdis: "Perfect balance of style and comfort for all-day wear",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/west2.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Hot pink cotton embroidered co ord set",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "35",
          type: "New",
          productdis: "Perfect balance of style and comfort for all-day wear",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/west3.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Pink printed crepe co ord set",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "25",
          type: "New",
          productdis: "Perfect balance of style and comfort for all-day wear",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
        {
          productimage:
            "../Images/DressCollection/west4.webp",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "salmon red velvet embroidered",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "30",
          type: "New",
          productdis: "Perfect balance of style and comfort for all-day wear",
          addpoints: ["Quality produt", "Best price", "Unique collection",],
          color: ["red", "blue", "yellow"],
          size: ["XS", "S", "M", "L", "XL","XXL"],
        },
      ],
    },
    {
      key: "saree",
      items: [
        {
          productimage:
            "../Images/DressCollection/saree1.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Purple mirror embroidered saree",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "12",
          type: "New",
          productdis: "Well-crafted and tailored for a flattering fit",
          addpoints: ["Quality produt", "Best price", "Unique Collection"],
          color: ["red", "blue", "yellow"],
          size: ["Free size"],
        },
        {
          productimage:
            "../Images/DressCollection/saree2.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Pink zari weaved saree",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "36",
          type: "New",
          productdis: "Well-crafted and tailored for a flattering fit",
          addpoints: ["Quality produt", "Best price", "Unique Collection"],
          color: ["red", "blue", "yellow"],
          size: ["Free size"],
        },
        {
          productimage:
            "../Images/DressCollection/saree3.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Teal blue patterned saree",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "24",
          type: "New",
          productdis: "Well-crafted and tailored for a flattering fit",
          addpoints: ["Quality produt", "Best price", "Unique Collection"],
          color: ["red", "blue", "yellow"],
          size: ["Free size"],
        },
        {
          productimage:
            "../Images/DressCollection/saree4.avif",
          producthoverimage:
            "https://demo.hasthemes.com/flone-preview/flone/assets/img/product/pro-5.jpg",
          title: "Rama green patterned saree",
          starcount: 3,
          originalprice: 2000,
          offerprice: 1000,
          offer: "10",
          type: "New",
          productdis: "Well-crafted and tailored for a flattering fit",
          addpoints: ["Quality produt", "Best price", "Unique Collection"],
          color: ["red", "blue", "yellow"],
          size: ["Free size"],
        },
      ],
    },
  ];
  if (page !== "All") {
    const productData = products.find((product) => product.key === page);
    var items = productData ? productData.items : [];
  } else {
    var allItems = products.reduce(
      (all, category) => all.concat(category.items),
      []
    );
  }

  return (
    <>
      <div className="products container">
        <div className="d-flex flex-wrap justify-conent-center align-items-center mt-5">
          {(page !== "All" ? items : allItems).map((item, index) => (
            <div
              className="col-xl-3 col-md-4 col-sm-6 col-12 product-view d-flex justify-content-center"
              onClick={() => handleProductClick(item)}
            >
              <div className="content my-md-5 my-3">
                {item.offer ? (
                  <span className="offer-details">{item.offer}%Off</span>
                ) : (
                  <span className="offer-details-new">{item.type}</span>
                )}
                <img className="product-image w-100" src={item.productimage} />
                {/* <h6 className="product-name text-center my-3 text-ellipsis">{item.title}</h6> */}
              </div>
            </div>
          ))}
          <MyVerticallyCenteredModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            product={selectedProduct}
          />
        </div>
      </div>
    </>
  );
}

export default Products;
