import React from "react";
import "./main.css";
import { Link } from "react-router-dom";
import HeroVideo from "../HeroVideo/herovideo";
import Maintitle from "../MainTitle/maintitle";
import Clients from "../Clients/clients";
import Testimonial from "../Testimonials/testimonial";
import SuccessStory from "../SuccessStory/successstory";

const Main = () => {
  const scrollToEvents = () => {
    const eventsSection = document.getElementById("eventsSection");
    if (eventsSection) {
      window.scrollTo({
        top: eventsSection.offsetTop,
        behavior: "smooth",
      });
    }
  };
  const scrollToAdvertisement = () => {
    const eventsSection = document.getElementById("Advertisement");
    if (eventsSection) {
      window.scrollTo({
        top: eventsSection.offsetTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      {/* <div classNameName="position-relative main-image">
      <h1 classNameName="text-center">Jaya Signatures <br/> Private Limited</h1>
    </div>
    <MainData/> */}
      {/* <h1 className="py-5 text-center title-content">
        Jaya Signatures Private Limited
      </h1> */}
      <HeroVideo />
      <div className="container">
        <div className="d-flex flex-wrap justify-content-lg-center justify-content-start my-5">
          <div className="card col-lg-3 col-sm-6 col-12 my-2">
            <img src="../../Images/Main/event2.avif" alt="EventS" />
            <div className="intro">
              <h1 className="text-h1">Events</h1>
              <p className="text-p">
                Transform ordinary gatherings into extraordinary experiences
                with our comprehensive event solutions. From concept to
                execution, we craft seamless and unforgettable moments tailored
                to your vision, ensuring every detail surpasses expectations.
              </p>
              <div className="btn btn-main-outlite " onClick={scrollToEvents}>
                LEARN MORE
              </div>
            </div>
          </div>

          <div className="card mx-lg-5 col-lg-3  col-sm-6 col-12 my-2">
            <img
              src="../../Images/Main/shoot2.jpg"
              alt="Advertisement"
            />
            <div className="intro">
              <h1 className="text-h1">Advertisement</h1>
              <p className="text-p">
                Captivate your audience with compelling storytelling! Our
                innovative advertising strategies create impactful narratives
                that resonate, ensuring your brand stands out in a crowded
                market.
              </p>
              <div
                className="btn btn-main-outlite "
                onClick={scrollToAdvertisement}
              >
                LEARN MORE
              </div>
            </div>
          </div>

          <div className="card col-lg-3 col-sm-6 col-12 my-2">
            <img
              src="../../Images/Main/shop.webp"
              alt="Boutique"
            />
            <div className="intro">
              <h1 className="text-h1">Boutique</h1>
              <p className="text-p">
                Indulge in an exclusive world of elegance and style at our
                boutique. Discover curated collections that speak to your
                individuality, where sophistication meets comfort.
              </p>
              <Link to="home">
                <div className="btn btn-main-outlite">LEARN MORE</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div id="eventsSection">
        <Maintitle title="Events" />
        <div className="container">
          <div className="d-flex flex-wrap-reverse justify-content-md-center justify-content-start  main-demo-data">
            <div className="col-md-6 col-12">
              <h2>Your Vision, Our Expertise</h2>
              <p>
                Step into a world where every event becomes an immersive
                journey. Our seasoned team specializes in curating bespoke
                experiences that transcend the ordinary. From corporate galas to
                intimate celebrations, we infuse creativity into every detail,
                ensuring your event resonates with authenticity and charm.
              </p>
              <p>
                We believe in crafting not just events, but lasting memories.
                Each occasion is a canvas, and we paint it with the hues of
                uniqueness, leaving an indelible mark on every attendee. Let us
                redefine what's possible and make your next event an
                extraordinary affair.
              </p>
              <Link to="/events">
                <div className="btn btn-main-outlite">LEARN MORE</div>
              </Link>
            </div>
            <div className="col-md-6 col-12 px-md-4 px-0 my-md-0 my-3">
              <img
                src="../../Images/Main/event.avif"
                className="main-image-new"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="Advertisement">
        <Maintitle title="Advertisement" />
        <div className="container">
          <div className="d-flex flex-wrap  justify-content-md-center justify-content-start   main-demo-data">
            <div className="col-md-6 col-12 px-md-4 px-0">
              <img
                src="../../Images/Main/craft.avif"
                className="main-image-new"
              />
            </div>
            <div className="col-md-6 col-12 my-md-0 my-3">
              <h2>Crafting Compelling Narratives</h2>
              <p>
                Welcome to the realm of impactful advertising where creativity
                meets strategy. Our team thrives on crafting campaigns that
                transcend the ordinary, weaving stories that captivate and
                resonate. From captivating visuals to compelling narratives, we
                blend innovation with market insight, ensuring your brand
                message resonates deeply. 
              </p>
              <p>
                We believe in more than just advertising; it's about creating
                experiences that stay etched in the minds of your audience. Our
                multi-channel approach combines digital finesse, traditional
                media, and experiential marketing, amplifying your brand's reach
                while forging meaningful connections.
              </p>
              <Link to="/advertisement">
                <div className="btn btn-main-outlite">LEARN MORE</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <SuccessStory />
      <Clients />
      <Testimonial />
      <div className="contact-us-main py-md-5 py-4 px-3">
        <h3 className="mb-0 text-center">
          Ready to celebrate your next big event?
        </h3>
        <p className="my-4 text-center">
          We are excited to share unique event ideas to woo your target
          audience.
        </p>
        <Link to="/contact-us">
          <div className="btn btn-main-outlite">Contact</div>
        </Link>
      </div>
    </>
  );
};

export default Main;
