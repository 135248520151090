import React from "react";
import "./hero.css";
import Maintitle from "../../../MainTitle/maintitle";
const Hero = () => {
  const aboutus = [
    {
      title1: "A Journey of Creativity and Excellence  ",
      des1: "Suresh Rajendran embarked on this thrilling journey with a passion for creativity and an unwavering commitment to excellence. Armed with a keen understanding of the advertisement industry, he has successfully navigated the diverse touch points of the Indian market, creating impactful campaigns that resonate with audiences across the nation.",
      title2: "Event Management Maestro",
      des2: "With a portfolio boasting more than 1000 projects, Suresh Rajendran stands as a stalwart in the event management arena. He has orchestrated events that transcend imagination, leaving a lasting impression on attendees and clients alike. Notably, Suresh Rajendran orchestrated an event featuring a staggering 6000 people, showcasing his prowess in handling large-scale productions with finesse and precision.",
    },
    {
      title1: "A Visionary on the Rise",
      des1: "Fuelled by a relentless passion for growth and innovation, Suresh Rajendran harbors the ambitious goal of transforming his company into a unicorn—a testament to his visionary leadership and dedication to achieving unprecedented success in the industry.",
      title2: "Diversification into the Boutique Industry",
      des2: "Ever the forward-thinker, Suresh Rajendran has recently diversified into the boutique industry. This move exemplifies his ability to adapt to emerging trends and capitalize on new opportunities. The boutique industry venture is a testament to Suresh Rajendran's entrepreneurial spirit and commitment to exploring uncharted territories.",
    },
    {
      title1: "Recent Ventures and Achievements",
      des1: "Suresh Rajendran has not only conquered the realms of advertising and event management but has also demonstrated a flair for identifying niche markets and successfully establishing businesses. The recent foray into the boutique industry showcases his ability to stay at the forefront of industry trends and make strategic business decisions.",
      title2: "Aiming for the Stars",
      des2: "As Suresh Rajendran continues to steer the ship towards success, he remains steadfast in the pursuit of turning his company into a unicorn. This vision is underpinned by a commitment to innovation, customer satisfaction, and fostering a workplace culture that inspires creativity and excellence.",
    },
  ];
  const content = [
    {
      Title: "Our Vision",
      Des: "At Jaya signatures Private Limited, our vision is to redefine experiences, creating unforgettable moments that resonate beyond events. We aim to be the catalyst for innovative and immersive events that captivate, inspire, and leave lasting impressions.",
      Image: "../../../../Images/Aboutus/vision-1.jpg",
    },
    {
      Title: "Our Mission",
      Des: "Our mission at Jaya signatures Private Limited  is to craft and execute events that transcend expectations. We aim to blend creativity, precision planning, and state-of-the-art technology, ensuring each event delivers unparalleled satisfaction to our clients and attendees.",
      Image: "../../../../Images/Aboutus/mission-1.jpg",
    },
    {
      Title: "Our Goal",
      Des: "At Jaya signatures Private Limited, our goal is to establish lasting relationships with our patrons by consistently delivering exceptional designs, fostering a community that celebrates diversity, and exceeding expectations in every aspect of our service.",
      Image: "../../../../Images/Aboutus/goal.jpg",
    },
  ];
  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-center">
          <div className="col-lg-6 col-12 d-flex flex-column align-items-center my-md-5 my-3">
            {/* <p className="mb-0 text-center">Who Are We</p>
            <h3 className="my-3 text-center line-lg">Welcome To Jaya signatures Private Limited </h3> */}
            <p className="text-center line-lg font-italic main-content">
             " Welcome to the dynamic world of Suresh Rajendran, a visionary
              entrepreneur and trailblazer in the Indian advertisement and event
              management industry. With an illustrious career spanning several
              years, Suresh Rajendran has left an indelible mark on the
              landscape of marketing and experiential events."
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <Maintitle title="About Suresh Rajendran" />
        <div className="d-flex flex-wrap justify-content-center about-person-content align-content-stretch my-md-5 my-3">
          {aboutus.map((i) => (
            <>
              <div className="col-lg-6 col-12 my-2">
                <div className="content-1 p-sm-5 p-3 m-2 h-100">
                  <h6>{i.title1}</h6>
                  <p>
                    {i.des1}
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12 my-2">
                <div className="content-2 p-sm-5 p-3 m-2 h-100">
                  <h6>{i.title2}</h6>
                  <p>
                   {i.des2}
                  </p>
                </div>
              </div>
            </>
          ))}
        </div>
      </div>

      <div className="container">
      <Maintitle title="Vision-Mission-Goal" />
        <div className="d-flex flex-wrap">
          {content.map((item, index) => (
            <div
              key={index}
              className="col-lg-4 col-md-6 col-sm-12 col-12 about-vision my-2"
            >
              <div className="px-2">
                <img src={item.Image} alt={item.Title} />
                <h5 className="my-3 fw-bold">{item.Title}</h5>
                <p className="line-lg about-us-content">{item.Des}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default Hero;
