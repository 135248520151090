import React from "react";
import "./notfound.css"
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <>
      <section classNameName="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-12 col-sm-offset-1  text-center d-flex flex-column align-items-center">
                <div className="four_zero_four_bg">
                </div>
                <h1 className="text-center my-3">404</h1>

                <div className="contant_box_404">
                  <h3 className="h2">Look like you're lost</h3>

                  <p>the page you are looking for not avaible!</p>

                  <Link className="link_404" to="/">
                    Go to Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NotFound;
