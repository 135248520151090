import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./header.css";

const HeaderSub = (props) => {
  const { page } = props;
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState(false);
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };
  useEffect(() => {
    setShowDropdown(false);
  }, [location.pathname]);
  return (
    <>
      <div
        className={
          page == "home"
            ? "d-flex justify-content-between col-8 align-items-center position-relative"
            : "col-8 responsive"
        }
      >
        <div
          className={
            page == "responsive"
              ? "d-flex flex-column justify-content-center px-2 mb-4 position-relative"
              : ""
          }
        >
          <Link to="/about-us">About</Link>
          <span className="no-res">
            <a onClick={toggleDropdown}>
              Services{" "}
              {showDropdown ? (
                <i class="fa-solid fa-caret-down no-res"></i>
              ) : (
                <i class="fa-solid fa-caret-up no-res"></i>
              )}
            </a>
          </span>
          <Link onClick={toggleDropdown} className="hidden-ui">Services</Link>

          <Link to="/contact-us">Contact</Link>
          {showDropdown && (
            <span className="dropdown-content">
              <Link to="/events" className="my-1">Events</Link>
              <Link to="/advertisement" className="my-md-3 my-1">
                Advertisement
              </Link>
              <Link to="/home" className="my-1">Boutique</Link>
            </span>
          )}
        </div>
        <div className="d-flex">
          <a>
            <i class="fa-brands fa-facebook"></i>
          </a>
          <a href="https://www.instagram.com/jayasignatures/" target="_blank">
            <i class="fa-brands fa-instagram"></i>
          </a>
          <a href="https://api.whatsapp.com/send?phone=919884413123">
            <i class="fa-brands fa-whatsapp"></i>
          </a>
          <a href="tel:9884413123">
            <i class="fa-solid fa-phone"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default HeaderSub;
