import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link,useLocation } from "react-router-dom";
import HeaderSub from "../Header/headersub";

export default function OffCanvasData({ name, ...props }) {
  const location = useLocation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    setShow(false);
  }, [location.pathname]);

  return (
    <>
      <Button variant="primary" onClick={handleShow} className="me-2">
        {name}
      </Button>
      <Offcanvas show={show} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{ fontSize: "17px", fontWeight: "bold",paddingBottom:"10px",borderBottom:"3px solid  #ebd49d" }}>Jaya Signatures Private Limited</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <HeaderSub page="responsive"/>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
