import React, { useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import OffCanvasData from "../OffCanvas/offcanvas";
import HeaderSub from "./headersub";


const Header = () => {
  return (
    <>
      <div className="header d-flex justify-content-between align-items-center">
        <div className="header-logo col-4">
          <Link to="/">
            <img src="../../Images/jayalogo1.png" />
          </Link>
        </div>
        <div className="bar-icon">
          <OffCanvasData name={<i class="fa-solid fa-bars"></i>} />
        </div>
        <div className="responsive-header">
          <HeaderSub page="home" />
        </div>
      </div>
    </>
  );
};

export default Header;
