import React from "react";

const Maintitle = (props) => {
  const { title } = props;
  return (
    <>
      <h2 className="section-title-main container my-md-5 my-3">
        <b></b>
        <span
          className={
            title === "About Jayapradha Mohanlal"
              ? "section-title px-3 about-title-jaya"
              : "section-title px-3"
          }
        >
          {title}
        </span>

        <b></b>
      </h2>
    </>
  );
};

export default Maintitle;
