import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import { FreeMode, Pagination, Autoplay } from "swiper/modules";
import "./clients.css";
import Maintitle from "../MainTitle/maintitle";


export default function Clients() {
  const Clients = [
    {
      Image1:
        "../../Images/Clients/ANS.jpeg",
        Image2:"../../Images/Clients/ARRS.jpg"
    },
    
    {
      Image1:
        "../../Images/Clients/derby.avif",
        Image2:
        "../../Images/Clients/GP.png",
    },
    
    {
      Image1: "../../Images/Clients/galaxy.png",
      Image2:
      "../../Images/Clients/grteducation.png"
    },
    {
      Image1: "../../Images/Clients/GRT.png",
        Image2:"../../Images/Clients/grthotel.jpg"
    },
   
    {
      Image1:"../../Images/Clients/Herologo.png",

      Image2:"../../Images/Clients/harsha.png",
    },
    {
      Image1: "../../Images/Clients/joyalukkas.png",

      Image2:
        "../../Images/Clients/indira.png",
    },
    
    {
      Image1:
        "../../Images/Clients/grthotel.jpg",
        Image2:
        "../../Images/Clients/khushi.png"
    },
  
    {
      Image1:
      "../../Images/Clients/malabar.jpeg",
      Image2:
        "../../Images/Clients/LKS.jpg",
    },

    {
      Image1:
      "../../Images/Clients/Reliancetrends.webp",
      Image2:
        "../../Images/Clients/marine.jpeg",
    },
    {
      Image1:
        "../../Images/Clients/RM.jpeg",
        Image2:
        "../../Images/Clients/sennttahotel.png"
    },
    
    {
      Image1:
        "../../Images/Clients/styleone.jpeg",
        Image2:
        "../../Images/Clients/sugam.png",
    },
    
    {
      Image1:
        "../../Images/Clients/lakshmi.jpg",
        Image2:
        "../../Images/Clients/VBJ.png",
    },
    {
      Image1:
      "../../Images/Clients/yamaha.jpg",
      Image2:
        "../../Images/Clients/Shree.jpg",
    },
    {
      Image1:
        "../../Images/Clients/Sugam.jpg",
        Image2:
        "../../Images/Clients/kala.png",
    },
   

  ];
  const [slidesPerView, setSlidesPerView] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSlidesPerView(2);
      }
      else if (window.innerWidth < 320) {
        setSlidesPerView(1);
      } else if (window.innerWidth < 992) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(4);
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="Client ">
        {/* <div className="d-flex justify-content-center align-items-center">
          <h5 className="hero-title client my-md-5 my-3">Our Client</h5>
        </div> */}
        <Maintitle title="Our clients" />
        <div className="container">
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={30}
            freeMode={true}
            pagination={{
              clickable: true,
            }}
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[FreeMode, Pagination,Autoplay]}
            className="mySwiper my-5"
          >
            {Clients.map((item, index) => (
              <SwiperSlide key={index} className="d-flex flex-column">
                <img
                  src={item.Image1}
                  className="client-product-name"
                  alt={`Our-Client-${index}`}
                />
                <img
                  src={item.Image2}
                  className="client-product-name my-3"
                  alt={`Our-Client-${index}`}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}
