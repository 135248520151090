import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "./dailyupdates.css";
import "../App.css"
import Products from "../Products/products";
import Maintitle from "../MainTitle/maintitle";

function DailyUpdates() {
  return (
    <>
      <div className="dailyupdates">
        {/* <div className="d-flex justify-content-center align-items-center">
          <h5 className="title my-md-5 my-3">DAILY DEALS!</h5>
        </div> */}
        <Maintitle title="DAILY DEALS!" />
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills" className="d-flex justify-content-center">
            <Nav.Item>
              <Nav.Link eventKey="first">New Arrivals  </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Best Sellers</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">Sale Items</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="first"><Products page="NewArr"/></Tab.Pane>
            <Tab.Pane eventKey="second"><Products page="seller"/></Tab.Pane>
            <Tab.Pane eventKey="third"><Products page="sale"/></Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </>
  );
}

export default DailyUpdates;
