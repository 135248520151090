import React from "react";
import "./footer.css";
import { Link, useLocation } from "react-router-dom";

export default function Footer() {
  const location = useLocation();
  const pathname = location.pathname;
  const boutique = pathname.includes("/shop");
  console.log("pathname", pathname);
  return (
    <>
      <div className={pathname == "/" ? "footer pt-5" : "footer active pt-5"}>
        <div className="container d-flex flex-wrap justify-content-between align-items-start">
          <div className="col-md-3 col-sm-6 col-12 mx-auto px-md-5">
            <h6 className="fw-bold">About</h6>
            <p className="my-3">
              Jaya Signatures Private Limited is your Event Branding and
              Promotion Partner in Chennai. We make your events impactful,
              memorable and achieve desired results.
            </p>
          </div>
          <div className="col-md-3 col-sm-6 col-12 mx-auto ps-md-5">
            <h6 className="fw-bold">Services</h6>
            <ul className="ps-0">
              <li>
                <Link to="events">Events</Link>
              </li>
              <li>
                <Link to="advertisement">Advertisement</Link>
              </li>
              <li>
                <Link to="home">Boutique </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-sm-6 col-12 mx-auto">
            <h6 className="fw-bold">Address</h6>
            {pathname == "/home" || boutique ? (
              <p>
                1215, 13th St, <br/> Z Block, G Block, <br/>
                Anna Nagar, <br />
                Chennai, <br />
                Tamil Nadu 600040
              </p>
             
            ) : (
              
               <p className="my-3">
               No: Y -79, 3rd Floor,
               <br /> 6th Street, Y-Block
               <br /> Anna Nagar, <br />
               Chennai, <br />
               Tamil Nadu 600040
             </p>
            )}
          </div>
          <div className="col-md-3 col-sm-6 col-12 mx-auto ps-md-5">
            <h6 className="fw-bold">Legal</h6>
            <p className="mb-0">
              <Link to="terms-and-conditions">Terms & Conditions</Link>
            </p>
            <p>
              <Link to="privacy-policy">Privacy Policy</Link>
            </p>
            <h6 className="fw-bold">Social</h6>
            <ul className="ps-0 d-flex flex-wrap">
              <li className="m-1">
                <a>
                  <i class="fa-brands fa-facebook"></i>
                </a>
              </li>
              <li className="m-1">
                <a
                  href="https://www.instagram.com/jayasignatures/"
                  target="_blank"
                >
                  <i class="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li className="m-1">
                <a href="https://api.whatsapp.com/send?phone=919884413123">
                  <i class="fa-brands fa-whatsapp"></i>
                </a>
              </li>
              <li className="m-1">
                <a href="tel:9884413123">
                  <i className="fa-solid fa-phone"></i>
                </a>
              </li>
            </ul>
          </div>

          {/* <div className="col-md-3 col-sm-6 col-12 mx-auto ps-md-5">
            <h6 className="fw-bold">Know More</h6>
            <ul className="ps-0">
              <li><Link to="terms-and-conditions">Terms and conditions</Link></li>
              <li><Link to="privacy-policy">privacy policy</Link></li>
            </ul>
          </div> */}
          {/* <div className="col-md-3 col-sm-6 col-12 mx-auto ps-md-5">
            <h6 className="fw-bold">Social</h6>
            <ul className="ps-0 d-flex flex-wrap">
              <li className="m-1"><a><i class="fa-brands fa-facebook"></i></a></li>
              <li className="m-1"><a><i class="fa-brands fa-instagram"></i></a></li>
              <li className="m-1"><a href="https://api.whatsapp.com/send?phone=919884413123"><i class="fa-brands fa-whatsapp"></i></a></li>
              <li className="m-1"><a href="tel:9884413123"><i className="fa-solid fa-phone"></i></a></li>
            </ul>
          </div> */}
        </div>
        <div className="py-3 footer-copy">
          <p className="text-center mb-0">
            CopyRight@2024 | Jaya Signatures Private Limited
          </p>
          <p className="text-center mb-0">
            All Rights Reserved |
            <a href="https://digitaltriumph.in/home" className="ps-1 text-dark">
              Digital Triumph
            </a>
          </p>
        </div>
      </div>
    </>
  );
}
