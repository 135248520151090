import React from "react";
import { Link } from "react-router-dom";
import Products from "../Products/products";

const Shop = () => {
  return (
    <>
      <div className="breadcrumb-area py-5">
        <div className="">
          <Link to="/home" className="non-active-content">
            Home
          </Link>
          <span className="mx-2">/</span>
          <Link to="/shop" className="active-content">
            Shop
          </Link>
        </div>
      </div>
      <Products page="All"/>
    </>
  );
};

export default Shop;
