import { Link } from "react-router-dom";
import "./contactus.css";
import { Emailcontactform } from "./emailcontactform";

function Contactus() {
  return (
    <>
      <div className="breadcrumb-area py-5">
        <div className="">
          <Link to="/" className="non-active-content">
            Home
          </Link>
          <span className="mx-2">/</span>
          <Link to="/contact-us" className="active-content">
            Contact
          </Link>
        </div>
      </div>
      <div className="container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.1172132773872!2d80.20671907489721!3d13.09175721223293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265c62bd9b6e9%3A0x5a3d0c7e81523f7d!2sJaya%20Signatures%20Boutique!5e0!3m2!1sen!2sin!4v1701931216103!5m2!1sen!2sin"
          style={{ width: "100%" }}
          height="400"
          allowfullscreen=""
          loading="lazy"
          className="my-md-5 my-3"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        <div className="d-flex flex-wrap contact-us-form my-5">
          <div className="col-lg-4 col-12">
            <div className="p-3 mx-2 left-side">
              <div className="d-flex flex-wrap align-items-center mb-4">
                <i class="fa-brands fa-whatsapp"></i>
                <a href="https://api.whatsapp.com/send?phone=919884413123">
                  98844 13123
                </a>
              </div>
              <div className="d-flex flex-wrap align-items-center mb-4">
                <i class="fa-regular fa-envelope"></i>
                <a href="mailto:suresh@jayasignatures.com">
                suresh@jayasignatures.com
                </a>
              </div>
              <div className="d-flex flex-wrap align-items-center mb-4">
                <i class="fa-solid fa-location-dot"></i>
                <a href="#">Anna nagar,chennai</a>
              </div>
              <div className="mb-4">
                <h4 className="my-3">Follow us</h4>
                <div className="d-flex flex-wrap">
                  <i className="fa-brands fa-facebook"></i>
                  <a
                    href="https://www.instagram.com/jayasignatures/"
                    target="_blank"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-12">
            <div className=" contact-form p-5 mx-2 right-side">
              <Emailcontactform />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contactus;
