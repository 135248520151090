import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./testimonial.css";
import { EffectFade, Navigation, Pagination } from "swiper/modules";
import Maintitle from "../MainTitle/maintitle";

export default function Testimonial() {
  const testimonial = [
    {
      Image:
        "../../Images/Clients/client-2.jpg",
      Clientname: "Maya",
      Clientlocation: "Alwarpet - Chennai",
      Clientdes:
        "Jaya Signatures Private Limited made our event an unforgettable success! Their dedication to excellence and meticulous planning ensured every detail was perfect",
    },
    {
      Image:
        "../../Images/Clients/location-1.jpg",
      Clientname: "Samira",
      Clientlocation: "Thoraipakkam - Chennai",
      Clientdes:
        "Choosing Jaya Signatures Private Limited for our advertising needs was the best decision. Their creative strategies and attention to our brand's identity resulted in an outstanding campaign.",
    },
    {
      Image:
        "../../Images/Clients/location-2.jpg",
      Clientname: "Nithya",
      Clientlocation: "Adyar - Chennai",
      Clientdes:
        "At Jaya Signatures Private Limited's boutique, I discovered a haven of style and sophistication. The personalized service and curated collections made my shopping experience truly exceptional.",
    },
    {
      Image:
        "../../Images/Clients/client-4.jpg",
      Clientname: "Devika",
      Clientlocation: "Anna Nagar - Chennai",
      Clientdes:
        "Jaya Signatures Private Limited brought our vision to life flawlessly. Their professionalism and creativity exceeded our expectations, making our event a standout moment.",
    },
  ];
  return (
    <>
      <div className="testimonial">
        {/* <div className="d-flex justify-content-center align-items-center">
          <h5 className="hero-title my-md-5 my-3">Testimonial</h5>
        </div> */}
         <Maintitle title="testimonial " />
        <div className="container">
          <Swiper
            spaceBetween={30}
            effect={"fade"}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            modules={[EffectFade, Navigation, Pagination]}
            className="mySwiper my-5"
          >
            {testimonial.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="d-flex flex-wrap justify-content-between align-items-center h-100 testimonial-border">
                  <div className="col-md-6 col-12 d-flex justify-content-center">
                    <img
                      className="client-image"
                      src={item.Image}
                      alt={item.Clientname}
                    />
                  </div>
                  <div className="col-md-6 col-12 p-5">
                    <h6 className="client-name">{item.Clientname}</h6>
                    <p className="location my-3">{item.Clientlocation}</p>
                    <p className="client-des">{item.Clientdes}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
}
